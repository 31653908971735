.imagenet {
  padding: 0 1%; }
  .imagenet .table {
    min-width: 500px;
    max-width: 600px; }
  .imagenet .disable {
    pointer-events: none; }
  .imagenet img {
    width: 300px;
    height: 100%; }
  .imagenet .sample-images, .imagenet .upload-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .imagenet .dropzone {
    width: 300px;
    height: 200px;
    box-sizing: border-box;
    border-width: 2px;
    border-color: #666666;
    border-style: dashed;
    border-radius: 5px;
    vertical-align: top;
    margin-right: 2%; }
