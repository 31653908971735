.Poly {
  margin: 0 1em; }

.Polynomial-input .input-textfield {
  margin-right: .9em;
  width: 15%; }

.Polynomial-input .input-title {
  display: inline-block;
  width: 15%; }

.line-chart {
  width: 70%;
  height: 400px;
  margin: auto; }
