.App-header {
  background-color: #CBFFD3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  /*
  color: white;
  */ }
  .App-header .logo-link {
    width: 12%; }
    .App-header .logo-link .App-logo {
      width: 100%;
      height: 100%; }
  .App-header .content {
    position: relative;
    width: 88%; }
    .App-header .content .logout {
      text-align: right; }
    .App-header .content .tabs {
      position: absolute;
      bottom: 0;
      width: 100%; }
