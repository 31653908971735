:local .counter .count > * {
  margin-right: 1%; }

:local .counter .networkd3 div {
  text-align: center;
  width: 60%;
  border: 1px solid black;
  margin: auto; }
  :local .counter .networkd3 div svg {
    margin: auto; }
