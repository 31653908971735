.AndGate-img {
  height: 80px;
  width: 240px;
  display: block;
  margin: auto; }

.AndGate-form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  /*
  margin-left: 30%;
  margin-right:30%;
  */
  margin: auto;
  font-size: 16px;
  width: 70%; }

.AndGate-form h2 {
  text-align: center; }

.AndGate-input {
  display: flex;
  flex-direction: column;
  align-content: space-around; }

.AndGate-input label, .AndGate-predict label {
  padding: 8px 0px;
  border: none;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: inherit;
  margin-right: 10px;
  width: 10%; }

.AndGate-input select, input {
  /*
  justify-content: center;
  flex-grow: 2;
  text-align: left;
  margin-right: 10px;
  */
  padding: .7em 1.5em;
  text-decoration: none;
  display: inline-block;
  font-size: inherit;
  width: max-content; }

.AndGate-buttons {
  padding: 8px 0px;
  display: flex; }

.AndGate-buttons button {
  padding: 8px 8px; }

.AndGate-buttons #predictButton {
  background-color: #ff6347;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: inherit;
  width: max-content;
  cursor: pointer; }

.AndGate-buttons #trainButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: inherit;
  margin-right: 10px;
  width: max-content;
  cursor: pointer; }

.AndGate-buttons #resetButton {
  background-color: #eb4a4a;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: inherit;
  margin-right: 10px;
  width: max-content;
  cursor: pointer; }

.AndGate-buttons #trainButton:active {
  background-color: #2e8f31;
  cursor: progress; }

.AndGate-buttons #resetButton:active {
  background-color: #dd2626;
  cursor: progress; }

.AndGate-buttons button:hover {
  box-shadow: 3px 3px #d7d8e7; }
