.mnistcnn .train-results .train-charts {
  width: 48%;
  margin: 1%;
  display: inline-block; }

.mnistcnn .train-results .line-chart {
  width: 100%;
  height: 300px;
  /*
      display: inline-block;
      */ }

.mnistcnn .embedding canvas {
  border: 1px black; }

.mnistcnn .displaynone {
  display: none; }

.mnistcnn .predicts .results {
  display: flex;
  flex-wrap: wrap; }

.mnistcnn .predicts .predict-image {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  margin-bottom: 1%; }
  .mnistcnn .predicts .predict-image .pred-incorrect {
    background-color: red;
    color: white; }
  .mnistcnn .predicts .predict-image .pred-correct {
    background-color: green;
    color: white; }
  .mnistcnn .predicts .predict-image .pred-canvas {
    width: 100px; }

.mnistcnn .mnistdata {
  display: none; }
